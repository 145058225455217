import {CODE_KEY} from 'constants/index';
import {toast} from 'react-toastify';

export function handleLazyLoadError(err) {
  console.error(err);
  window.location.reload();
}

export function handleErrorResponse(error, callback = null) {
  if (callback) callback();

  if (error?.status !== CODE_KEY.UNAUTHORIZED_STATUS) {
    if (error?.data?.meta?.msg)
      toast.error(error.data.meta.msg, {autoClose: 2000, position: 'top-center'});
    else console.error('error from handleErrorResponse', error);
  }
}

export function handleErrorAuthResponse(error, callback = null) {
  if (callback) callback();

  if (typeof error.response !== 'undefined') {
    if (error?.status !== CODE_KEY.UNAUTHORIZED_STATUS) {
      if (error.response?.data?.messages.length > 0) {
        error.response.data.messages?.forEach(function (e) {
          toast.error(e.content, {autoClose: 2000, position: 'top-center'});
        });
      } else console.error('error from handleErrorAuthResponse', error);
    }
  }
}

import ImgLogoBackground from 'images/logo-background.png';
import ImgBackground from 'images/background-homePC.jpg';
import ImgFooterHomePC from 'images/background-homePC-footer.png';
import ImgLogoHomePC from 'images/logo-homePC.png';
import SVGFullscreen from 'images/icon/fullscreen.svg';
import SVGCloseCircle from 'images/icon/close-circle.svg';
import ImgCoinCS from 'images/coinCS.png';

export const IMAGE_LINKS = {
  BACKGROUND: ImgBackground,
  FOOTER: ImgFooterHomePC,
  LOGO_HOME: ImgLogoHomePC,
  LOGO_BACKGROUND: ImgLogoBackground,
  COIN_CS: ImgCoinCS,
};

export const ICON_LINKS = {
  FULL_SCREEN: SVGFullscreen,
  CLOSE_CIRCLE: SVGCloseCircle,
};
